import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'windi.css'
import './global.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VueCarousel from 'vue-carousel';

import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility);
Vue.use(VueCarousel);

Vue.config.productionTip = false

Vue.filter("currency", function(value) {
  if (!value) return "$0.00";
  // 如果是字符串，先转换成数字
  if (typeof value === "string") {
    value = parseInt(value);
  }
  // 格式化成金额字符串
  value = value / 100;
  return "$" + value.toFixed(2);
});
Vue.filter("toHTML", function(value) {
  if (!value) return "";
  // 把换行符替换为<br />
  value = value.replace(/\n/g, "<br />");
});
Vue.use(ElementUI);

Vue.prototype.shopName = "Eastern Shop";


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: "Home"
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
    }
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      title: "test"
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/TestView.vue')
    }
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    meta: {
      title: "productDetail"
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/ProductDetailView.vue')
    }
  },
  {
    path: '/categoryDetail',
    name: 'categoryDetail',
    meta: {
      title: "categoryDetail"
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/CategoryDetailView.vue')
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
